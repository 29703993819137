import { useCallback, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { Button, Grid, TextField } from '@mui/material';
import { flow } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useOverlay } from '../../../features/Overlay';
import { FORM, STRINGS } from '../../../constants';
import { ErrorUtil, FormUtil, ParcelUtil, StringUtil } from '../../../utils';
import parcelSlice from '../../../redux/parcelSlice';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { resetFields } from '../helpers';
import { FIELDS_TO_RESET_ON_PARCEL_CHANGE } from '../constants';

const SearchParcelFields = ({ parcelNumber, postcode, setParcel, errors }) => {
  const overlay = useOverlay();
  const form = useForm();
  const dispatch = useDispatch();
  const { showError } = useCustomSnackbar();

  const [parcelError, setParcelError] = useState(null);
  const [disabledSearch, setIsDisabledSearch] = useState(false);

  const handleFetch = useCallback(async () => {
    if (parcelNumber) {
      try {
        overlay.show();

        setIsDisabledSearch(true);

        const parcelCode = await dispatch(
          parcelSlice.actions.fetchParcelCodeByParcelNumber({
            parcelNumber,
            postcode,
          })
        ).unwrap();

        if (parcelCode) {
          const data = await dispatch(
            parcelSlice.actions.fetchParcelByCode({
              parcelCode,
              postcode,
            })
          ).unwrap();

          resetFields(FIELDS_TO_RESET_ON_PARCEL_CHANGE, form);

          setParcel(data);
        }
      } catch (error) {
        setParcel(null);

        if (ErrorUtil.isNotFoundError(error)) {
          setParcelError(STRINGS.NO_SEARCH_SEARCH_RESULT);
        } else if (ErrorUtil.isBadRequestError(error)) {
          setParcelError(STRINGS.INCORRECT_PARCEL_NUMBER);
        } else {
          showError({ message: STRINGS.FETCHING_PARCEL_ERROR });
        }
      } finally {
        overlay.hide();
      }
    }
  }, [parcelNumber, overlay, form, dispatch, postcode, setParcel, showError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md='auto'>
        <Field
          name={FORM.HELP_FIELDS.PARCEL_NUMBER.KEY}
          parse={flow([
            StringUtil.replaceLetters,
            ParcelUtil.normalizeParcelNumber,
          ])}
        >
          {({ input, meta }) => (
            <TextField
              label={FORM.HELP_FIELDS.PARCEL_NUMBER.LABEL}
              fullWidth
              size='small'
              required
              error={!!(meta.touched && meta.error) || parcelError}
              helperText={(meta.touched && meta.error) || parcelError}
              {...input}
              onChange={e => {
                setParcelError(null);
                input.onChange(e);
                setIsDisabledSearch(false);
              }}
              sx={{ width: { md: 236, xs: '100%' } }}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md='auto'>
        <Field
          name={FORM.HELP_FIELDS.POSTCODE.KEY}
          parse={StringUtil.trimFirstSpace}
          {...FormUtil.fieldTrimOnBlur}
        >
          {({ input, meta }) => (
            <TextField
              label={FORM.HELP_FIELDS.POSTCODE.LABEL}
              fullWidth
              required
              size='small'
              error={!!meta.touched && meta.error}
              helperText={meta.touched && meta.error}
              {...input}
              onChange={e => {
                input.onChange(e);
                setIsDisabledSearch(false);
              }}
              sx={{ width: { md: 176, xs: '100%' } }}
            />
          )}
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
        md='auto'
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          variant='contained'
          type='button'
          size='medium'
          onClick={handleFetch}
          disabled={
            parcelError ||
            errors?.[FORM.HELP_FIELDS.PARCEL_NUMBER.KEY] ||
            errors?.[FORM.HELP_FIELDS.POSTCODE.KEY] ||
            disabledSearch
          }
          sx={{ height: 40 }}
        >
          {STRINGS.SUBMIT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchParcelFields;

SearchParcelFields.propTypes = {
  parcelNumber: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  setParcel: PropTypes.func.isRequired,
  errors: PropTypes.object,
};
