import { STRINGS } from '../../../../constants';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { StringUtil } from '../../../../utils';
import DeleteIconButton from './components/DeleteIconButton';

export const PRODUCT_TABLE_CONFIG = (currency, isDescriptionOnly, onDelete) => {
  const renderButton = (item, disabled) => (
    <DeleteIconButton onClick={onDelete} item={item} disabled={disabled} />
  );

  return isDescriptionOnly
    ? {
        columns: [
          {
            path: SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY,
            heading: STRINGS.PRODUCT_DESCRIPTION,
            width: '65%',
          },
          {
            render: renderButton,
            align: 'right',
            heading: ' ',
          },
        ],
      }
    : {
        columns: [
          {
            path: SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY,
            heading: STRINGS.PRODUCT_DESCRIPTION,
            width: '65%',
          },
          {
            path: SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY,
            heading: STRINGS.PRODUCT_QUANTITY,
            width: '10%',
          },
          {
            path: SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY,
            heading: StringUtil.formatMessage(
              STRINGS.PRODUCT_UNIT_VALUE_$,
              STRINGS.CURRENCY_SYMBOLS[currency]
            ),
            width: '25%',
            headColspan: 2,
          },
          {
            render: renderButton,
            align: 'right',
          },
        ],
      };
};
export const TOTAL_TABLE_CONFIG = currency => ({
  columns: [
    {
      heading: ' ',
      width: '10%',
    },
    {
      bodyColspan: 2,
      path: 'quantity',
      heading: STRINGS.TOTAL_PRODUCTS,
      align: 'right',
      width: '30%',
    },
    {
      path: 'weight',
      heading: STRINGS.TOTAL_WEIGHT,
      align: 'right',
      width: '30%',
    },
    {
      path: 'value',
      heading: StringUtil.formatMessage(
        STRINGS.TOTAL_VALUE_$,
        STRINGS.CURRENCY_SYMBOLS[currency]
      ),
      align: 'left',
      width: '30%',
    },
  ],
});
