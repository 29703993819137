import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { parcelsApis } from '../apis';
import { ErrorUtil, ParcelUtil } from '../utils/';

const initialState = {
  status: 'idle',
  data: {},
};

const fetchParcelCodeByParcelNumber = createAsyncThunk(
  'parcels/fetchParcelCodeByParcelNumber',
  async ({ parcelNumber, postcode }, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelCodeByParcelNumber({
        parcelNumber,
        postcode,
      });

      return response?.data?.parcelCode;
    } catch (error) {
      if (error.response) {
        if (ErrorUtil.isBadRequestError(error.response.data.error)) {
          return rejectWithValue(ErrorUtil.formatParcelError(error));
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const fetchParcelByNumber = createAsyncThunk(
  'parcel/fetchByNumber',
  async (parcelNumber, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelByNumber(parcelNumber);

      return response.data;
    } catch (error) {
      if (error.response) {
        if (ErrorUtil.isBadRequestError(error.response.data.error)) {
          return rejectWithValue(ErrorUtil.formatParcelError(error));
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const fetchParcelByCode = createAsyncThunk(
  'parcel/fetchByCode',
  async ({ parcelCode, postcode }, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelByCode({
        parcelCode,
        postcode,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (ErrorUtil.isBadRequestError(error.response.data.error)) {
          return rejectWithValue(ErrorUtil.formatParcelError(error));
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const slice = createSlice({
  name: 'parcel',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParcelByNumber.fulfilled, (state, { payload }) => {
        state.data[ParcelUtil.normalizeParcelNumber(payload.parcelNumber)] =
          payload;
        state.status = 'success';
      })
      .addCase(fetchParcelByCode.fulfilled, (state, { payload }) => {
        state.data[ParcelUtil.normalizeParcelNumber(payload.parcelCode)] =
          payload;
        state.status = 'success';
      });
  },
});

const getParcelState = state => state.parcel;

const getParcel = parcelNumber =>
  createSelector(getParcelState, state => ({
    data: state.data[parcelNumber],
    status: state.status,
  }));

export default {
  reducer: slice.reducer,
  actions: {
    fetchParcelCodeByParcelNumber,
    fetchParcelByNumber,
    fetchParcelByCode,
  },
  selectors: {
    getParcel,
  },
};
