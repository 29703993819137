import { cloneDeep, get, isEmpty, set } from 'lodash';
import { InvoiceHelpers, ShipmentHelpers } from '../../../../helpers';
import { FORM, STRINGS } from '../../../../constants';
import { REFERENCE_NAME } from '../../constants';
import { ObjectUtil } from '../../../../utils';
import { NETWORK_FIELDS } from '../../../../constants/forms';
import { getTotalParcelsValue } from '../../../../helpers/shipment';

export const getInvoiceAvailableFields = ({ values, references = {} }) => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(
    references,
    get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
  );
  const isBusiness = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(saebiFlags);
  const isUkimsAvailable = ShipmentHelpers.isUkimsAvailable(values);
  const isIossAvailable = !ShipmentHelpers.isFlagNeedles(
    saebiFlags,
    NETWORK_FIELDS.TAX_REQUIRED
  );

  return {
    isInvoiceAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isInvoiceAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY]:
      ShipmentHelpers.isAtRiskAvailable(values, isInvoiceAvailable),
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]: isBusiness,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]: isBusiness,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      isInvoiceAvailable && !isBusiness,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: isInvoiceAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY]: isInvoiceAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]:
      ShipmentHelpers.isFdaNumberAvailable(saebiFlags),
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.KEY]: isIossAvailable,
  };
};

const isDeliveryDescriptionAvailable = (values, references = {}) => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(
    references,
    get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
  );

  return (
    ShipmentHelpers.isInvoiceAvailable(saebiFlags) &&
    !ShipmentHelpers.isFlagNeedles(saebiFlags, NETWORK_FIELDS.CONTENTS_REQUIRED)
  );
};

export const getAllowedFields = (values, references, currentSession) => ({
  ...getInvoiceAvailableFields({
    values,
    references,
  }),
  [FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY]: get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
    false
  ),
  [FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION.KEY]:
    isDeliveryDescriptionAvailable(values, references),
  [FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY]: get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  ),
  saveForNextTime:
    !get(values, FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  saveForNextTimeReceiver:
    !get(values, FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});

export const getActivePickupPoints = references => ({
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});

export const getFieldsToUpdate = ({
  shipment,
  isBusiness,
  atRisk,
  references = {},
}) => {
  const fieldsToReset = [];
  const fieldsToUpdate = {
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isBusiness,
  };
  const isFdaAvailable = ShipmentHelpers.isFdaNumberAvailable(
    ShipmentHelpers.getSaebiFlags(
      references,
      get(shipment, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
    )
  );
  const isNiShipment = ShipmentHelpers.isNiShipment(shipment);
  const isUkimsAvailable = isNiShipment && isBusiness && !atRisk;

  if (!isFdaAvailable) {
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY);
  }

  if (isBusiness) {
    fieldsToUpdate[FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY] = get(
      shipment,
      FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
      ''
    );
    fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY] = get(
      references?.deliveryAddressBook,
      FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY,
      ''
    );
    fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY] = get(
      references?.deliveryAddressBook,
      FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY,
      ''
    );
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY);

    if (isNiShipment) {
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY] =
        atRisk;
    }

    if (isUkimsAvailable) {
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY] =
        get(
          references?.deliveryAddressBook,
          FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
          ''
        );
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY] =
        get(
          references?.collectionAddressBook,
          FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
          ''
        );
    }
  } else {
    fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY] = get(
      references?.deliveryAddressBook,
      FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY,
      ''
    );
    fieldsToReset.push(
      FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
    );

    if (isNiShipment) {
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY] = false;
    }
  }

  return {
    fieldsToUpdate: ObjectUtil.replaceEmptyStringToUndefined(fieldsToUpdate),
    fieldsToReset,
  };
};

const setInvoiceExporterDetails = (clonedValues, addressBook) => {
  // TODO: refactor - don't use mutation
  set(
    clonedValues,
    'invoice.exporterDetails',
    get(clonedValues, FORM.SHIPMENT_FIELDS.COLLECTION_DETAILS.KEY)
  );
  set(
    clonedValues,
    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
    InvoiceHelpers.getDefaultIsBusiness(clonedValues, {
      businessPath: FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
      organisationPath: FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
    })
  );

  set(
    clonedValues,
    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY,
    get(addressBook, FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY, '')
  );
  set(
    clonedValues,
    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY,
    get(addressBook, FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY, '')
  );

  if (ShipmentHelpers.isUkimsAvailable(clonedValues)) {
    set(
      clonedValues,
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY,
      get(addressBook, FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY, '')
    );
  }
};

const setInvoiceImporterDetails = (clonedValues, addressBook) => {
  // TODO: refactor - don't use mutation
  const defaultIsBusiness = InvoiceHelpers.getDefaultIsBusiness(clonedValues, {
    businessPath: FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    organisationPath: FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
  });

  set(
    clonedValues,
    'invoice.importerDetails',
    get(clonedValues, FORM.SHIPMENT_FIELDS.DELIVERY_DETAILS.KEY)
  );
  set(
    clonedValues,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    defaultIsBusiness
  );

  if (defaultIsBusiness) {
    set(clonedValues, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY, false);
    set(
      clonedValues,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY,
      get(addressBook, FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY, '')
    );
    set(
      clonedValues,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY,
      get(addressBook, FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY, '')
    );

    if (ShipmentHelpers.isUkimsAvailable(clonedValues)) {
      set(
        clonedValues,
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
        get(addressBook, FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY, '')
      );
    }
  } else {
    set(
      clonedValues,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY,
      get(addressBook, FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY, '')
    );
  }
};

const setInvoiceFields = (clonedValues, references = {}) => {
  // TODO: refactor - don't use mutation
  const invoice = get(clonedValues, 'invoice', {});
  const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(
    ShipmentHelpers.getSaebiFlags(
      references,
      get(clonedValues, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
    )
  );
  isInvoiceAvailable &&
    set(
      clonedValues,
      FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY,
      ShipmentHelpers.getTotalParcelsValue(
        get(clonedValues, FORM.SHIPMENT_FIELDS.PARCELS.KEY, [])
      )
    );

  if (!isInvoiceAvailable && !isEmpty(invoice)) {
    delete clonedValues.invoice;
    delete clonedValues.outboundConsignment.currency;
    delete clonedValues.outboundConsignment.customsValue;
    return;
  }

  if (isInvoiceAvailable && isEmpty(invoice)) {
    set(clonedValues, FORM.SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP);
    // TODO: set correct value depending on business decision
    set(clonedValues, FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY, 1);
    // NOTE: set importer details first to proper setting exporter details (related to isBusiness)
    setInvoiceImporterDetails(clonedValues, references?.deliveryAddressBook);
    setInvoiceExporterDetails(clonedValues, references?.collectionAddressBook);
  }
};

export const getAddressDetailsInitialValues = (values, references) => {
  const clonedValues = cloneDeep(values);
  setInvoiceFields(clonedValues, references);

  return ObjectUtil.replaceEmptyStringToUndefined(clonedValues);
};
