/* eslint-disable max-lines */
/* eslint-disable complexity */
import { cloneDeep, get, isBoolean, isEmpty, isNil, set } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';

import { Radios } from 'mui-rff';
import { ANALYTICS, FORM, POINT_TYPE, STRINGS } from '../../../../constants';
import InformationCollecting from '../../../../components/InformationCollecting/index';
import AddressWidget from '../../../../components/AddressWidget';
import DropDown from '../../../../components/DropDown/';
import PickupMapImage from '../../../../components/MapBox/components/PickupMapImage';
import { useReferencesLoader } from '../../hooks';
import { useOverlay } from '../../../../features/Overlay';
import {
  DEFAULT_BOOLEAN_KEY_VALUE,
  REFERENCE_NAME,
  USER_TYPES,
} from '../../constants';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { useAuth } from '../../../../features/Auth';
import Loader from '../../../../features/Loader';
import { Formatters, Normalizers, useAnalytics } from '../../../../utils';
import { ShipmentHelper as PageShipmentHelpers } from '../../helpers';
import Debugger from '../../../../components/Debugger';
import {
  FormHelpers,
  InvoiceHelpers,
  NetworkHelpers,
  ShipmentHelpers,
} from '../../../../helpers';
import OrderForm from '../../components/OrderForm';
import Summary from '../../components/Summary';
import { FormInput } from '../../../../components/FormInput';
import * as OrderSelectors from '../../selectors';
import * as AddressDetailsSelectors from './selectors';
import * as AddressDetailsHelpers from './helpers';

// Form field wrapper styled for flex layout
const FieldWrapper = ({ children, sx }) => (
  <Box
    sx={{
      width: { xs: '100%', md: '50%' },
      p: 1,
      pb: 2,
      ...sx,
    }}
  >
    {children}
  </Box>
);

const AddressDetails = ({
  nextStep,
  previousStep,
  values,
  setValues,
  references,
  goToNamedStep,
}) => {
  const { setInterfaceId, Trackable, trackAction } = useAnalytics();
  const snackbar = useCustomSnackbar();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const companyNameRef = useRef(null);
  const receiverCompanyNameRef = useRef(null);
  const [saveForNextTime, setSaveForNextTime] = useState(false);
  const [saveForNextTimeReceiver, setSaveForNextTimeReceiver] = useState(false);
  const overlay = useOverlay();
  const { currentSession } = useAuth();
  const referencesLoader = useReferencesLoader();
  const availableFields = useSelector(state =>
    AddressDetailsSelectors.getAvailableFields(state, currentSession)
  );
  const { collectionPickupPoint, destinationPickupPoint } = useSelector(
    AddressDetailsSelectors.getPickupPoints
  );
  const { requiredFields, requiredKeys } = useSelector(
    AddressDetailsSelectors.getRequiredData
  );
  const currencies = InvoiceHelpers.getCurrenciesKeyValue(
    references.currencies
  );
  const exportReasons = InvoiceHelpers.filterExportReasons(
    references.exportReasons,
    values
  );
  const basketItemId = searchParams.get('basketItemId');
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );
  const context = useSelector(OrderSelectors.getValidationContext);
  const customsValueLabel = useMemo(
    () =>
      FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.LABEL(
        PageShipmentHelpers.getCurrencySymbol(
          get(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP)
        )
      ),
    [get(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY)]
  );

  const companyName = get(
    values,
    FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
  );
  const receiverCompanyName = get(
    values,
    FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
  );

  useEffect(() => {
    setInterfaceId(ANALYTICS.ORDER_ADDRESS_DETAILS.INTERFACE_ID);
  }, []);

  useEffect(() => {
    if (receiverCompanyNameRef.current && receiverCompanyName?.length > 15) {
      receiverCompanyNameRef.current.focus();
      receiverCompanyNameRef.current.blur();
    }
    if (companyNameRef.current && companyName?.length > 15) {
      companyNameRef.current.focus();
      companyNameRef.current.blur();
    }
  }, [receiverCompanyNameRef.current, companyNameRef.current]);

  const handleBack = useCallback(
    event => {
      trackAction(event);
      previousStep();
    },
    [previousStep, setValues, values]
  );

  const saveAddressBook = useCallback(
    details =>
      dispatch(
        addressBooksSlice.actions.createAddressBook(
          PageShipmentHelpers.mapAddressDetailsToAddressBook(details)
        )
      ).unwrap(),
    []
  );

  const onSubmit = useCallback(
    async formValues => {
      try {
        overlay.show();
        const values = cloneDeep(formValues);

        if (saveForNextTime) {
          const { addressBookId } = await saveAddressBook({
            ...get(values, FORM.SHIPMENT_FIELDS.COLLECTION_DETAILS.KEY),
            vatNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
            ),
            eoriNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY
            ),
            ukimsNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
            ),
            iossNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.KEY
            ),
          });
          set(
            values,
            FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
            addressBookId
          );
        }
        if (saveForNextTimeReceiver) {
          const { addressBookId } = await saveAddressBook({
            ...get(values, FORM.SHIPMENT_FIELDS.DELIVERY_DETAILS.KEY),
            pidNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
            ),
            eoriNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY
            ),
            vatNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
            ),
            ukimsNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY
            ),
            iossNumber: get(
              values,
              FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.KEY
            ),
          });
          set(
            values,
            FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
            addressBookId
          );
        }

        const updatedBasketItem = {
          basketItemId: basketItemId,
          shipment: values,
        };

        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();

        if (availableFields.isInvoiceAvailable) {
          goToNamedStep('customsDetails', values);
        } else {
          goToNamedStep('payment', values);
        }
      } catch (e) {
        snackbar.showError({
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [
      dispatch,
      nextStep,
      overlay,
      searchParams,
      saveForNextTime,
      saveForNextTimeReceiver,
    ]
  );

  const loadInitialData = useCallback(async () => {
    let deliveryAddressBook, collectionAddressBook;

    const [networks, niRequiredFields] = await Promise.all([
      referencesLoader.loadNetworks(
        NetworkHelpers.getNetworkQuery(basketItem?.shipment)
      ),
      referencesLoader.loadNiRequiredFields(
        NetworkHelpers.getNiRequiredFieldsQuery(basketItem?.shipment)
      ),
      referencesLoader.loadDropOffCollection(),
      referencesLoader.loadDeliveryPickup(),
      referencesLoader.loadCollectionAddress(),
      referencesLoader.loadDeliveryAddress(),
    ]);

    if (
      ShipmentHelpers.isInvoiceAvailable(
        ShipmentHelpers.getSaebiFlags(
          { networks, niRequiredFields },
          get(basketItem?.shipment, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
        )
      )
    ) {
      const promises = [
        referencesLoader.loadShipmentAddressBookByType('delivery'),
        referencesLoader.loadShipmentAddressBookByType('collection'),
        isEmpty(references.currencies) && referencesLoader.loadCurrencies(),
        isEmpty(references.exportReasons) &&
          referencesLoader.loadExportReasons(),
      ].filter(Boolean);

      [deliveryAddressBook, collectionAddressBook] = await Promise.all(
        promises
      );
    }

    return AddressDetailsHelpers.getAddressDetailsInitialValues(
      basketItem?.shipment,
      { networks, niRequiredFields, deliveryAddressBook, collectionAddressBook }
    );
  }, []);

  return (
    <Trackable loadId={ANALYTICS.ORDER_ADDRESS_DETAILS.LOAD}>
      <Loader promiseFn={loadInitialData}>
        {initialValues => (
          <OrderForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            context={{
              ...context,
              withContactDetails: true,
            }}
            requiredKeys={requiredKeys}
          >
            {({ submitting, values, form, errors, invalid }) => {
              const handleBusinessChange = async (isBusiness, atRisk) => {
                const niRequiredFields =
                  await referencesLoader.loadNiRequiredFields({
                    ...NetworkHelpers.getNiRequiredFieldsQuery(values),
                    isBusiness,
                    atRisk,
                  });

                const { fieldsToUpdate, fieldsToReset } =
                  AddressDetailsHelpers.getFieldsToUpdate({
                    shipment: basketItem.shipment,
                    isBusiness,
                    atRisk,
                    references: {
                      ...references,
                      niRequiredFields,
                    },
                  });
                FormHelpers.bulkUpdate(form, fieldsToUpdate);
                FormHelpers.bulkReset(form, fieldsToReset);
              };

              const onIossBlur = event => {
                const value = Formatters.trim(event.target.value);
                const gstValue = get(
                  values,
                  FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_GST_VAT_PAID.KEY
                );

                form.change(event.target.name, value);

                if (value && isNil(gstValue)) {
                  form.change(
                    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_GST_VAT_PAID.KEY,
                    false
                  );
                }

                if (!value && isBoolean(gstValue)) {
                  form.change(
                    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_GST_VAT_PAID.KEY,
                    undefined
                  );
                }
              };

              return (
                <form id='addressForm'>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} md={8}>
                      {availableFields.isInvoiceAvailable && (
                        <Paper
                          sx={{
                            p: 2,
                            mb: 2,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography variant='h3'>
                            {STRINGS.ADDRESS_DETAILS.CUSTOMS_DETAILS}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              my: 1,
                            }}
                          >
                            <FieldWrapper>
                              <DropDown
                                name={
                                  FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON
                                    .LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON
                                      .KEY
                                  ]
                                }
                                options={exportReasons}
                              />
                            </FieldWrapper>

                            <FieldWrapper>
                              <DropDown
                                name={FORM.SHIPMENT_FIELDS.CURRENCY.KEY}
                                label={FORM.SHIPMENT_FIELDS.CURRENCY.LABEL}
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS.CURRENCY.KEY
                                  ]
                                }
                                options={currencies}
                              />
                            </FieldWrapper>

                            {availableFields[
                              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS
                                .KEY
                            ] && (
                              <>
                                <FieldWrapper>
                                  <FormInput
                                    name={
                                      FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY
                                    }
                                    label={customsValueLabel}
                                    fullWidth
                                    disabled
                                  />
                                </FieldWrapper>
                                <FieldWrapper>
                                  <DropDown
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_EXPORTER_IS_BUSINESS.KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_EXPORTER_IS_BUSINESS.LABEL
                                    }
                                    options={USER_TYPES}
                                    required={
                                      requiredFields[
                                        FORM.SHIPMENT_FIELDS
                                          .INVOICE_IMPORTER_IS_BUSINESS.KEY
                                      ]
                                    }
                                    fieldProps={{
                                      parse: value =>
                                        Normalizers.booleanOrNullValueNormalize(
                                          value
                                        ),
                                    }}
                                  />
                                </FieldWrapper>

                                <OnChange
                                  name={
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_IS_BUSINESS.KEY
                                  }
                                >
                                  {isBusiness =>
                                    form.change(
                                      FORM.SHIPMENT_FIELDS
                                        .COLLECTION_ORGANISATION.KEY,
                                      isBusiness
                                        ? get(
                                            initialValues,
                                            FORM.SHIPMENT_FIELDS
                                              .COLLECTION_ORGANISATION.KEY,
                                            undefined
                                          )
                                        : undefined
                                    )
                                  }
                                </OnChange>

                                <FieldWrapper>
                                  <DropDown
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_IMPORTER_IS_BUSINESS.KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_IMPORTER_IS_BUSINESS.LABEL
                                    }
                                    options={USER_TYPES}
                                    fieldProps={{
                                      parse: value =>
                                        Normalizers.booleanOrNullValueNormalize(
                                          value
                                        ),
                                    }}
                                    onChange={async event => {
                                      await handleBusinessChange(
                                        Normalizers.booleanOrNullValueNormalize(
                                          event.target.value
                                        ),
                                        false
                                      );
                                    }}
                                  />
                                </FieldWrapper>

                                <FieldWrapper>
                                  <FormInput
                                    sx={{ mb: 2 }}
                                    name={
                                      FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION
                                        .KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION
                                        .LABEL
                                    }
                                    fullWidth
                                    fieldProps={{
                                      formatOnBlur: true,
                                      format: Formatters.trim,
                                    }}
                                    required={
                                      requiredFields[
                                        FORM.SHIPMENT_FIELDS
                                          .DELIVERY_DESCRIPTION.KEY
                                      ]
                                    }
                                  />
                                </FieldWrapper>
                              </>
                            )}
                          </Box>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY
                          ] && (
                            <FieldWrapper
                              sx={{
                                width: { xs: '100%' },
                              }}
                            >
                              <FormControl
                                component='fieldset'
                                sx={{
                                  p: 2,
                                  backgroundColor: '#E5F6FD',
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={7}>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: 16,
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      {STRINGS.AT_RISK_TITLE}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Pluto Sans DPD',
                                        fontSize: '12px',
                                        fontWeight: 370,
                                        lineHeight: '14.82px',
                                        letterSpacing: '0.17px',
                                        textAlign: 'justify',
                                        mt: 1,
                                      }}
                                    >
                                      {STRINGS.AT_RISK_TEXT}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <Radios
                                      radioGroupProps={{
                                        row: true,
                                      }}
                                      name={
                                        FORM.SHIPMENT_FIELDS
                                          .INVOICE_IMPORTER_AT_RISK.KEY
                                      }
                                      required={true}
                                      data={DEFAULT_BOOLEAN_KEY_VALUE}
                                      fieldProps={{
                                        parse: value =>
                                          Normalizers.booleanOrNullValueNormalize(
                                            value
                                          ),
                                        format: value =>
                                          Normalizers.booleanOrNullValueFormat(
                                            value
                                          ),
                                      }}
                                      onChange={async event => {
                                        await handleBusinessChange(
                                          get(
                                            values,
                                            FORM.SHIPMENT_FIELDS
                                              .INVOICE_IMPORTER_IS_BUSINESS.KEY,
                                            false
                                          ),
                                          Normalizers.booleanOrNullValueNormalize(
                                            event.target.value
                                          )
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </FieldWrapper>
                          )}
                        </Paper>
                      )}

                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant='h3'>
                          {STRINGS.ADDRESS_DETAILS.DETAILS_TITLE}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            my: 1,
                          }}
                        >
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                          ] && (
                            <FieldWrapper sx={{ width: '100%' }}>
                              <FormInput
                                label={STRINGS.ADDRESS_DETAILS.COMPANY_NAME}
                                fullWidth
                                name={
                                  FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION
                                    .KEY
                                }
                                inputRef={companyNameRef}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.CONTACT_NAME}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME
                                    .KEY
                                ]
                              }
                              fieldProps={{
                                formatOnBlur: true,
                                format: Formatters.trim,
                              }}
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.EMAIL}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL
                                  .KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL
                                    .KEY
                                ]
                              }
                              fieldProps={{
                                formatOnBlur: true,
                                format: Formatters.trim,
                              }}
                              // TODO: show error from initial values
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.PHONE}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS
                                  .COLLECTION_CONTACT_TELEPHONE.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS
                                    .COLLECTION_CONTACT_TELEPHONE.KEY
                                ]
                              }
                              // TODO: show error from initial values
                            />
                          </FieldWrapper>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_EORI_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_EORI_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_EORI_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_VAT_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_VAT_NUMBER.LABEL
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_UKIMS_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_UKIMS_NUMBER.LABEL
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_FDA_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_FDA_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_FDA_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_IOSS_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_IOSS_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_IOSS_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                onBlur={onIossBlur}
                              />
                            </FieldWrapper>
                          )}
                          <AddressWidget
                            address={get(
                              values,
                              FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY,
                              {}
                            )}
                            onEditClick={handleBack}
                          />
                          {availableFields.saveForNextTime && (
                            <FormControlLabel
                              sx={{ pl: 2 }}
                              control={
                                <Checkbox
                                  onChange={setSaveForNextTime}
                                  name='saveForNextTime'
                                  value={saveForNextTime}
                                />
                              }
                              label={STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME}
                            />
                          )}
                        </Box>
                      </Paper>
                      {availableFields.collectionPickupPoint && (
                        <PickupMapImage
                          title={STRINGS.YOUR_PICKUP_POINT}
                          pickupPoint={collectionPickupPoint}
                          buttonLabel={STRINGS.CHANGE_YOUR_PICKUP_POINT}
                          buttonOnClick={handleBack}
                        />
                      )}
                      <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant='h3'>
                          {STRINGS.ADDRESS_DETAILS.RECEIVER_DETAILS_TITLE}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            my: 1,
                          }}
                        >
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                          ] && (
                            <FieldWrapper sx={{ width: '100%' }}>
                              <FormInput
                                label={STRINGS.ADDRESS_DETAILS.COMPANY_NAME}
                                fullWidth
                                name={
                                  FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                                    .KEY
                                }
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                                inputRef={receiverCompanyNameRef}
                              />
                            </FieldWrapper>
                          )}
                          <FieldWrapper>
                            <FormInput
                              label={
                                STRINGS.ADDRESS_DETAILS.RECEIVER_CONTACT_NAME
                              }
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                                ]
                              }
                              fieldProps={{
                                formatOnBlur: true,
                                format: Formatters.trim,
                              }}
                              // TODO: investigate why notificationDetails is removed if it's empty
                              onChange={event =>
                                form.change(
                                  event.target.name,
                                  event.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.RECEIVER_EMAIL}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL
                                    .KEY
                                ]
                              }
                              fieldProps={{
                                formatOnBlur: true,
                                format: Formatters.trim,
                              }}
                              // TODO: show error from initial values
                              // TODO: investigate why notificationDetails is removed if it's empty
                              onChange={event =>
                                form.change(
                                  event.target.name,
                                  event.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.RECEIVER_PHONE}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE
                                    .KEY
                                ]
                              }
                              // TODO: show error from initial values
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY
                            }
                          >
                            {value => {
                              form.change(
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_TELEPHONE
                                  .KEY,
                                value
                              );
                            }}
                          </OnChange>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_EORI_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_EORI_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_EORI_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_VAT_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_VAT_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_VAT_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_UKIMS_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_UKIMS_NUMBER.LABEL
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_PID_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_PID_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_PID_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: Formatters.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          <AddressWidget
                            address={get(
                              values,
                              FORM.SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY,
                              {}
                            )}
                            onEditClick={handleBack}
                          />
                          {availableFields.saveForNextTimeReceiver && (
                            <FormControlLabel
                              sx={{ pl: 2 }}
                              control={
                                <Checkbox
                                  onChange={setSaveForNextTimeReceiver}
                                  name='saveForNextTimeReceiver'
                                  value={saveForNextTimeReceiver}
                                />
                              }
                              label={STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME}
                            />
                          )}
                        </Box>
                      </Paper>
                      {availableFields.destinationPickupPoint && (
                        <PickupMapImage
                          title={STRINGS.RECEIVER_PICKUP_POINT}
                          pickupPoint={destinationPickupPoint}
                          buttonLabel={STRINGS.CHANGE_YOUR_PICKUP_POINT}
                          buttonOnClick={handleBack}
                        />
                      )}
                      <Debugger>
                        <pre>{JSON.stringify(errors, null, 2)}</pre>
                      </Debugger>
                      <InformationCollecting sx={{ pl: 2, pr: 2 }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Summary submitDisabled={submitting || invalid} />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </OrderForm>
        )}
      </Loader>
    </Trackable>
  );
};

export default AddressDetails;
