import moment from 'moment-timezone';

import { DAYS_LIMIT_FOR_CLAIM_PROCESSING, ISSUE_TYPES } from './constants';

export const isClaimInvalid = deliveredDate => {
  const currentDate = moment();

  return (
    deliveredDate &&
    currentDate.diff(deliveredDate, 'days') > DAYS_LIMIT_FOR_CLAIM_PROCESSING
  );
};

export const getValidationContext = issueType => {
  if (!issueType) {
    return null;
  }

  const isGoodsValueRequired = issueType !== ISSUE_TYPES.MISSING_CONTENTS.KEY;
  const isPackageDescriptionRequired =
    issueType === ISSUE_TYPES.NOT_ARRIVED.KEY;
  const isAdditionalInfoRequired = issueType === ISSUE_TYPES.NOT_ARRIVED.KEY;
  const isDamageDescriptionRequired = issueType !== ISSUE_TYPES.NOT_ARRIVED.KEY;
  const isMissingDescriptionRequired =
    issueType === ISSUE_TYPES.MISSING_CONTENTS.KEY;
  const isDamagedItemRequired = issueType === ISSUE_TYPES.DAMAGED.KEY;

  return {
    isGoodsValueRequired,
    isPackageDescriptionRequired,
    isAdditionalInfoRequired,
    isDamageDescriptionRequired,
    isMissingDescriptionRequired,
    isDamagedItemRequired,
  };
};

export const resetFields = (fields, form) => {
  fields.forEach(field => {
    if (form.getFieldState(field)) {
      form.change(field, undefined);
      form.resetFieldState(field);
    }
  });
};
