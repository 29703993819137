import { get, isEqual, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useWizard } from '../../../features/Wizard';
import { FORM, STRINGS } from '../../../constants';
import { locationsApis, vouchersApis } from '../../../apis';
import { REFERENCE_NAME } from '../constants';
import * as AppActions from '../../../redux/appActions';
import { CurrenciesActions } from '../../../redux/currenciesSlice';
import { ShipmentHelpers } from '../../../helpers';
import { ExportReasonsActions } from '../../../redux/exportReasonSlice';
import { NiRequiredFieldsActions } from '../../../redux/niRequiredFieldsSlice';
import { networksActions } from '../../../redux/networksSlice';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { StringUtil } from '../../../utils';
import addressBooksSlice from '../../../redux/addressBooksSlice';
import { BasketSelectors } from '../../../redux/basket';
import { ShipmentHelper as OrderHelper } from '../helpers';

export const useReferencesLoader = () => {
  const { values, references, setReferences } = useWizard();
  const dispatch = useDispatch();
  const snackbar = useCustomSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const previousDeliverTo = useRef(
    get(values, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY)
  );

  const previousCollectFrom = useRef(
    get(values, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY)
  );

  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, searchParams.get('basketItemId'))
  );
  const isNiShipment = useMemo(
    () => ShipmentHelpers.isNiShipment(values),
    [values]
  );

  const loadDropOffCollection = useCallback(async () => {
    const collectionPickup = references[REFERENCE_NAME.COLLECTION_PICKUP];
    const dropOffPickupLocationCode = get(
      values,
      FORM.SHIPMENT_FIELDS.DROP_OFF_PICKUP_LOCATION_CODE.KEY
    );

    if (dropOffPickupLocationCode && !collectionPickup) {
      const collectionPickupResult = await locationsApis.getPickupLocationsById(
        dropOffPickupLocationCode
      );

      setReferences(REFERENCE_NAME.COLLECTION_PICKUP, {
        pickupLocation: collectionPickupResult.data,
      });
    }
  }, [references.collectionPickup, setReferences, values]);

  const loadDeliveryPickup = useCallback(async () => {
    const deliveryPickup = references[REFERENCE_NAME.DELIVERY_PICKUP];
    const pickupLocationCode = get(
      values,
      FORM.SHIPMENT_FIELDS.PICKUP_LOCATION_CODE.KEY
    );

    if (pickupLocationCode && !deliveryPickup) {
      const deliveryPickupResult = await locationsApis.getPickupLocationsById(
        pickupLocationCode
      );
      setReferences(REFERENCE_NAME.DELIVERY_PICKUP, {
        pickupLocation: deliveryPickupResult.data,
      });
    }
  }, [references.deliveryPickup, setReferences, values]);

  const loadDeliveryAddress = useCallback(async () => {
    const deliveryAddressReferences =
      references[REFERENCE_NAME.DELIVERY_ADDRESS];
    const deliveryAddressValues = get(
      values,
      FORM.SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY
    );

    if (!deliveryAddressReferences) {
      const referenceAddress = await dispatch(
        AppActions.fetchAddressDetails(deliveryAddressValues)
      ).unwrap();
      setReferences(REFERENCE_NAME.DELIVERY_ADDRESS, referenceAddress);
    }
  }, [references.deliveryAddress, setReferences, values, dispatch]);

  const loadCollectionAddress = useCallback(async () => {
    const collectionAddressReferences =
      references[REFERENCE_NAME.COLLECTION_ADDRESS];
    const collectionAddressValues = get(
      values,
      FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY
    );

    if (!collectionAddressReferences) {
      const referenceAddress = await dispatch(
        AppActions.fetchAddressDetails(collectionAddressValues)
      ).unwrap();
      setReferences(REFERENCE_NAME.COLLECTION_ADDRESS, referenceAddress);
    }
  }, [references.deliveryAddress, setReferences, values]);

  const loadShipmentAddressBookByType = useCallback(
    async type => {
      const { referencePath, fieldPath } =
        OrderHelper.getAddressBookFieldsPath(type);
      const addressBookId = get(basketItem?.shipment, fieldPath, '');
      const existedAddressBook = get(references, referencePath, {});

      if (addressBookId) {
        if (existedAddressBook?.addressBookId === addressBookId) {
          return existedAddressBook;
        }

        const addressBook = await dispatch(
          addressBooksSlice.actions.getAddressBookById(addressBookId)
        ).unwrap();

        setReferences(referencePath, addressBook);
        return addressBook;
      }

      return existedAddressBook;
    },
    [references, dispatch, setReferences, basketItem]
  );

  const loadCurrencies = useCallback(async () => {
    try {
      const currencies = await dispatch(
        CurrenciesActions.fetchCurrencies()
      ).unwrap();
      setReferences(REFERENCE_NAME.CURRENCIES, currencies);
    } catch (error) {
      snackbar.showError({
        message: StringUtil.formatMessage(
          STRINGS.FAILED_TO_GET_$,
          'currencies'
        ),
        persist: true,
      });
      setReferences(REFERENCE_NAME.CURRENCIES, []);
    }
  }, [dispatch, setReferences]);

  const loadExportReasons = useCallback(async () => {
    try {
      const exportReasons = await dispatch(
        ExportReasonsActions.fetchExportReasons()
      ).unwrap();
      setReferences(REFERENCE_NAME.EXPORT_REASON, exportReasons);
    } catch (error) {
      snackbar.showError({
        message: StringUtil.formatMessage(
          STRINGS.FAILED_TO_GET_$,
          'reasons for export'
        ),
        persist: true,
      });
      setReferences(REFERENCE_NAME.EXPORT_REASON, []);
    }
  }, [dispatch, setReferences]);

  const loadNetworks = useCallback(
    query =>
      dispatch(networksActions.fetchNetworks(query))
        .unwrap()
        .then(networks => {
          setReferences(REFERENCE_NAME.NETWORKS, networks);
          return networks;
        })
        .catch(() => {
          snackbar.showError({
            message: STRINGS.NO_NETWORKS_ERROR,
            persist: true,
          });
          setReferences(REFERENCE_NAME.NETWORKS, []);
        }),
    [dispatch, setReferences, snackbar.showError]
  );

  const loadNiRequiredFields = useCallback(
    async query => {
      if (isNiShipment) {
        try {
          const niRequiredFields = await dispatch(
            NiRequiredFieldsActions.fetchNiRequiredFields(query)
          ).unwrap();
          setReferences(REFERENCE_NAME.NI_REQUIRED_FIELDS, niRequiredFields);
          return niRequiredFields;
        } catch (error) {
          snackbar.showError({
            message: StringUtil.formatMessage(
              STRINGS.FAILED_TO_GET_$,
              'NI required fields'
            ),
            persist: true,
          });
          setReferences(REFERENCE_NAME.NI_REQUIRED_FIELDS, []);
        }
      }
    },
    [dispatch, setReferences, isNiShipment]
  );

  const removeVoucherIdFromQueryParams = useCallback(() => {
    const queryParams = Object.fromEntries(searchParams);

    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams(omit(queryParams, 'voucherId')).toString(),
      },
      {
        replace: true,
      }
    );
  }, [searchParams, navigate]);

  const deleteVoucher = useCallback(() => {
    removeVoucherIdFromQueryParams();
    setReferences(REFERENCE_NAME.VOUCHER, null);
  }, [removeVoucherIdFromQueryParams, setReferences]);

  const loadVoucher = useCallback(async () => {
    const { voucherId } = Object.fromEntries(searchParams);
    try {
      const voucher = await vouchersApis.getVoucherById(voucherId);
      setReferences(REFERENCE_NAME.VOUCHER, voucher);
    } catch (err) {
      deleteVoucher();
      snackbar.showWarning({
        message: STRINGS.INVALID_VOUCHER,
      });
    }
  }, [searchParams, setReferences, deleteVoucher, snackbar]);

  const newDeliverTo = get(values, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY);

  useEffect(() => {
    if (!isEqual(previousDeliverTo.current, newDeliverTo)) {
      setReferences(REFERENCE_NAME.DELIVERY_PICKUP, null);
      setReferences(REFERENCE_NAME.COLLECTION_PICKUP, null);
      previousDeliverTo.current = newDeliverTo;
    }
  }, [newDeliverTo]);

  const newCollectFrom = get(values, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY);

  useEffect(() => {
    if (!isEqual(previousCollectFrom.current, newCollectFrom)) {
      setReferences(REFERENCE_NAME.COLLECTION_PICKUP, null);
      previousCollectFrom.current = newCollectFrom;
    }
  }, [newCollectFrom]);

  return {
    loadDropOffCollection,
    loadDeliveryPickup,
    loadCollectionAddress,
    loadShipmentAddressBookByType,
    loadDeliveryAddress,
    loadCurrencies,
    loadExportReasons,
    loadNetworks,
    loadNiRequiredFields,
    loadVoucher,
    deleteVoucher,
  };
};
