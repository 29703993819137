import apis from './apis';

export const getCommodities = async args => {
  const response = await apis.get('references/commodities', {
    params: {
      ...args,
    },
  });

  const { data } = response;

  return data;
};
