import PropTypes from 'prop-types';
import { Box, Paper, Grid, Divider } from '@mui/material';

const SectionWrapper = ({
  titleLeftComponent,
  titleRightComponent,
  children,
  sx,
}) => (
  <Box sx={{ height: '100%', ...sx }}>
    <Paper sx={{ height: 'inherit' }}>
      <Grid
        container
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'inherit',
        }}
      >
        <Grid item sx={{ maxWidth: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid
              container
              sx={{
                padding: 2,
                pb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {titleLeftComponent && <Box>{titleLeftComponent}</Box>}
              {titleRightComponent && <Box>{titleRightComponent}</Box>}
            </Grid>
            <Divider />
            <Box sx={{ padding: 2, overflowX: 'auto' }}>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  </Box>
);

SectionWrapper.propTypes = {
  titleLeftComponent: PropTypes.node,
  titleRightComponent: PropTypes.node,
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default SectionWrapper;
