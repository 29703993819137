import { SHIPMENT_FIELDS } from '../../../../constants/forms';

export const SECTION = {
  DELIVERY_ADDRESS: 'deliveryAddress',
  COLLECTION_ADDRESS: 'collectionAddress',
  PARCEL: 'parcel',
  DELIVER_TO: 'deliverTo',
  COLLECT_FROM: 'collectFrom',
  SHIPMENT_DATE: 'shipmentDate',
  NETWORK: 'network',
  INSURANCE: 'insurance',
};

export const SECTION_FIELDS_MAP = {
  [SECTION.DELIVERY_ADDRESS]: [
    // NOTE: Relevant for reset fields here
    { name: SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY },
    { name: SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY },
    { name: SHIPMENT_FIELDS.DESTINATION_STREET.KEY },
    { name: SHIPMENT_FIELDS.DESTINATION_CITY.KEY },
    { name: SHIPMENT_FIELDS.DESTINATION_ADDRESS_KEY.KEY, focusable: false },
  ],
  [SECTION.COLLECTION_ADDRESS]: [
    // NOTE: Relevant for reset fields here
    { name: SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY },
    { name: SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY },
    { name: SHIPMENT_FIELDS.COLLECTION_STREET.KEY },
    { name: SHIPMENT_FIELDS.COLLECTION_CITY.KEY },
    { name: SHIPMENT_FIELDS.COLLECTION_ADDRESS_KEY.KEY, focusable: false },
  ],
  // Note: Other parcels should be populated where used
  [SECTION.PARCEL]: [
    { name: SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(0) },
    { name: SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(0) },
  ],
  [SECTION.DELIVER_TO]: [
    { name: SHIPMENT_FIELDS.DELIVER_TO.KEY, focusable: false },
    { name: SHIPMENT_FIELDS.PICKUP_DETAILS.KEY },
  ],
  [SECTION.COLLECT_FROM]: [
    { name: SHIPMENT_FIELDS.COLLECT_FROM.KEY, focusable: false },
    { name: SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY },
  ],
  [SECTION.SHIPMENT_DATE]: [{ name: SHIPMENT_FIELDS.SHIPMENT_DATE.KEY }],
  [SECTION.NETWORK]: [
    { name: SHIPMENT_FIELDS.NETWORK_CODE.KEY, focusable: false },
    { name: SHIPMENT_FIELDS.NETWORK_DESC.KEY },
    { name: SHIPMENT_FIELDS.NETWORK_BUS_ZONE.KEY },
  ],
  [SECTION.INSURANCE]: [
    { name: SHIPMENT_FIELDS.LIABILITY.KEY, focusable: false },
    { name: SHIPMENT_FIELDS.LIABILITY_VALUE.KEY, focusable: false },
  ],
};
