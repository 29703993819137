import { useCallback, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { debounce } from 'lodash';
import { useWizard } from './Context';

const WizardForm = ({
  children,
  subscribeOnChange = true,
  getFormApi,
  ...props
}) => {
  const { setValues, values } = useWizard();

  // NOTE: Initial values can be set after asynchronous operation, please be aware how often you change them (better not more than once)
  const stepInitialValues = useMemo(
    () => props.initialValues || values,
    [props.initialValues]
  );

  // NOTE: Debounce form values change to hav smother UX (important)
  const onChangeFormValues = useCallback(
    debounce(({ values: formValues }) => setValues(formValues), 100),
    [setValues]
  );

  return (
    <Form initialValues={stepInitialValues} {...props}>
      {fProps => {
        if (getFormApi) {
          getFormApi(fProps.form);
        }

        return (
          <>
            {typeof children === 'function' ? children(fProps) : children}
            {subscribeOnChange && (
              <FormSpy
                subscription={{ values: true }}
                onChange={onChangeFormValues}
              />
            )}
          </>
        );
      }}
    </Form>
  );
};

export default WizardForm;
