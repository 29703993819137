import { Box, Grid, Paper, Typography } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { Field, useForm } from 'react-final-form';
import { useEffect } from 'react';
import { FORM, STRINGS } from '../../../constants';
import {
  ACCEPT_IMAGE_MIME_TYPE,
  FIELDS_TO_RESET,
  ISSUE_TYPES,
  MAX_IMAGE_SIZE,
  MULTILINE_FIELDS,
} from '../constants';
import { StringUtil } from '../../../utils';
import ImageDropzone from '../../../components/ImageDropzone/ImageDropzone';
import { resetFields } from '../helpers';
import DropzoneInnerText from './DropzoneInnerText';

const ClaimDetails = ({ issueType, isAvailable }) => {
  const form = useForm();
  const formFieldsConfig = FORM.HELP_FIELDS[issueType];
  const formFieldsKeys = Object.keys(formFieldsConfig);

  useEffect(() => {
    resetFields(FIELDS_TO_RESET, form);
  }, [form, issueType]);

  return (
    <Paper sx={{ p: 2, mb: 2, mt: 2, opacity: isAvailable ? 1 : 0.4 }}>
      <Typography variant='h3' sx={{ mb: 1 }}>
        {issueType === ISSUE_TYPES.DAMAGED.KEY
          ? STRINGS.TELL_ABOUT_DAMAGE
          : STRINGS.TELL_ABOUT_PARCEL}
      </Typography>
      {formFieldsKeys.map(fieldKey => {
        const fieldName = formFieldsConfig[fieldKey].KEY;
        const fieldLabel = formFieldsConfig[fieldKey].LABEL;
        const fieldPlaceholder = formFieldsConfig[fieldKey].PLACEHOLDER;
        const isRequired = formFieldsConfig[fieldKey].REQUIRED;

        return (
          <Grid item xs={12} sx={{ mt: 2 }} key={fieldLabel}>
            <TextFieldRFF
              disabled={!isAvailable}
              name={fieldName}
              label={fieldLabel}
              placeholder={fieldPlaceholder}
              size='small'
              required={isRequired}
              {...(MULTILINE_FIELDS.includes(fieldName) && {
                multiline: true,
                rows: 4,
              })}
              fieldProps={
                fieldName === FORM.HELP_KEYS.GOODS_VALUE && {
                  parse: value => StringUtil.restrictToDigitsAndLimit(value, 8),
                  format: value => StringUtil.formatPrice(value),
                }
              }
            />
          </Grid>
        );
      })}
      <Box sx={{ mt: 2 }}>
        <Typography variant='body2' sx={{ mb: 1 }}>
          {issueType === ISSUE_TYPES.DAMAGED.KEY
            ? STRINGS.UPLOAD_IMAGES_OF_DAMAGE
            : STRINGS.UPLOAD_FILES}
        </Typography>
        <Field
          component={ImageDropzone}
          name={FORM.HELP_KEYS.ATTACHED_IMAGES}
          disabled={!isAvailable}
          accept={ACCEPT_IMAGE_MIME_TYPE}
          maxSize={MAX_IMAGE_SIZE}
          innerContent={<DropzoneInnerText />}
          showErrors
          showPreview
        />
      </Box>
    </Paper>
  );
};

export default ClaimDetails;
