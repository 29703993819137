import { Grid, Paper, Typography } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { FORM, STRINGS } from '../../../constants';

const ContactDetails = () => (
  <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
    <Typography variant='h3' sx={{ mb: 1 }}>
      {STRINGS.YOUR_CONTACT_DETAILS}
    </Typography>
    <Grid item xs={12} sx={{ mt: 3 }}>
      <TextFieldRFF
        name={FORM.HELP_FIELDS.CONTACT_NAME.KEY}
        required
        label={FORM.HELP_FIELDS.CONTACT_NAME.LABEL}
        size='small'
      />
    </Grid>
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <TextFieldRFF
          name={FORM.HELP_FIELDS.EMAIL.KEY}
          required
          label={FORM.HELP_FIELDS.EMAIL.LABEL}
          size='small'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldRFF
          name={FORM.HELP_FIELDS.PHONE.KEY}
          required
          label={FORM.HELP_FIELDS.PHONE.LABEL}
          size='small'
        />
      </Grid>
    </Grid>
  </Paper>
);

export default ContactDetails;
