import Dropzone from 'react-dropzone';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import ImageThumb from './components/ImageThumb';

const ImageDropzone = ({
  input: { value, onChange },
  disabled,
  innerContent,
  showPreview,
  ...props
}) => {
  const theme = useTheme();

  const onDropAccepted = acceptedFiles => {
    const validFiles = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    onChange(value ? [...value, ...validFiles] : [...validFiles]);
  };

  const removeFile = fileToRemove => {
    const updatedFiles = value
      ? value.filter(file => file !== fileToRemove)
      : [];
    onChange(updatedFiles);
    URL.revokeObjectURL(fileToRemove.preview);
  };

  return (
    <Dropzone onDropAccepted={onDropAccepted} {...props}>
      {({ getRootProps, getInputProps }) => (
        <Box>
          <Box
            {...getRootProps()}
            sx={{
              border: `2px dashed ${theme.palette.divider}`,
              borderRadius: 2,
              cursor: !disabled && 'pointer',
            }}
          >
            <input {...getInputProps()} />
            {innerContent}
          </Box>

          {showPreview && (
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}
            >
              {value &&
                value.map((file, index) => (
                  <ImageThumb
                    image={file.preview}
                    alt={file.name}
                    onRemoveClick={() => removeFile(file)}
                    key={`${file.name}-${index}`}
                  />
                ))}
            </Box>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

ImageDropzone.propTypes = {
  innerContent: PropTypes.node,
  disabled: PropTypes.bool,
  showErrors: PropTypes.bool,
  showPreview: PropTypes.bool,
  ...Dropzone.propTypes,
};

export default ImageDropzone;
