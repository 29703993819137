import { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import WizardForm from '../../../features/Wizard/Form';
import { Validators } from '../../../utils';
import { ShipmentValidator } from '../validators';
import { FormHelpers } from '../../../helpers';

const OrderForm = ({ children, onSubmit, context, requiredKeys, ...props }) => {
  const formRef = useRef();

  const registeredFields = useMemo(() => {
    if (formRef.current) {
      return formRef.current.getRegisteredFields();
    }
  }, [formRef.current]);

  const schema = useMemo(
    () =>
      Validators.requireKeysSchema(
        ShipmentValidator.shipmentSchema(context),
        requiredKeys
      ),
    [requiredKeys, context]
  );
  const validate = useCallback(
    values => {
      let valuesToValidate = values;

      const errors = Validators.validateWithJoi(valuesToValidate, schema, {
        allowUnknown: true,
      });
      let allocatedErrors = {};

      if (errors) {
        allocatedErrors = FormHelpers.hasErrorInRegisteredFields(
          errors,
          registeredFields
        );
      }

      return allocatedErrors;
    },
    [schema, JSON.stringify(registeredFields)]
  );

  const getFormApi = useCallback(formApi => {
    if (!formRef.current) {
      formRef.current = formApi;
    }
  }, []);

  return (
    <WizardForm
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur
      getFormApi={getFormApi}
      {...props}
    >
      {children}
    </WizardForm>
  );
};

OrderForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.object,
  requiredKeys: PropTypes.arrayOf(PropTypes.string),
};

export default OrderForm;
