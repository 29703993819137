import { get, isEmpty } from 'lodash';
import { FORM, POINT_TYPE } from '../constants';
import { REQUIRED_TYPE } from '../constants/shipment';
import { AddressUtil, NetworkUtil, ObjectUtil } from '../utils';
import { PostcodeHelpers } from '../helpers';

export const getReceiverContactName = shipment =>
  get(shipment, FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY, '');

export const isFlagRequired = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.MANDATORY;

export const isFlagNeedles = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.NEEDLESS;

export const isFlagOptional = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.OPTIONAL;

export const isFlagDescription = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.DESCRIPTION;

export const getSaebiFlags = (references = {}, selectedNetworkKey) => {
  const selectedNetwork = NetworkUtil.getNetworkByCode(
    references?.networks,
    selectedNetworkKey
  );

  return isEmpty(references?.niRequiredFields)
    ? selectedNetwork
    : references?.niRequiredFields;
};

export const isInvoiceAvailable = saebiFlags => {
  const requiredTypes = [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL];
  const properties = [
    get(saebiFlags, FORM.NETWORK_FIELDS.EXPORT_REASON_REQUIRED, ''),
    get(saebiFlags, FORM.NETWORK_FIELDS.DT1_AVAILABLE, ''),
    get(saebiFlags, FORM.NETWORK_FIELDS.DDP_AVAILABLE, ''),
    get(saebiFlags, FORM.NETWORK_FIELDS.FDA_REQUIRED, ''),
    get(saebiFlags, FORM.NETWORK_FIELDS.COST_REQUIRED, ''),
  ];
  const isProformaAvailable = requiredTypes.includes(
    get(saebiFlags, FORM.NETWORK_FIELDS.PROFORMA, '')
  );
  const isInvoiceAvailable = isFlagRequired(
    saebiFlags,
    FORM.NETWORK_FIELDS.EXPORT_IMPORT_REQUIRED
  );

  return (
    isProformaAvailable &&
    (isInvoiceAvailable ||
      properties.includes(REQUIRED_TYPE.MANDATORY) ||
      properties.includes(REQUIRED_TYPE.OPTIONAL))
  );
};

export const isNiShipment = shipment =>
  AddressUtil.isNiShipment(
    get(shipment, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY, ''),
    get(shipment, FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY, '')
  );

export const isAtRiskAvailable = (values, isInvoiceAvailable) =>
  isInvoiceAvailable &&
  get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  isNiShipment(values);

export const isFdaNumberAvailable = saebiFlags =>
  !isFlagNeedles(saebiFlags, FORM.NETWORK_FIELDS.PROFORMA) &&
  !isFlagNeedles(saebiFlags, FORM.NETWORK_FIELDS.FDA_REQUIRED);

export const isUkimsAvailable = values =>
  isNiShipment(values) &&
  get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  !get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY, false);

export const isShipmentDomestic = shipment =>
  AddressUtil.isCountryDomestic(
    get(shipment, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY)
  );

export const isEoriRequired = (values, saebiFlags) =>
  get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  (isFlagRequired(saebiFlags, FORM.NETWORK_FIELDS.EORI_REQUIRED) ||
    get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY, ''));

export const isUkimsRequired = (values, saebiFlags) => {
  const exporterUkimsNumber = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY,
    ''
  );
  const importerUkimsNumber = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
    ''
  );
  const isBusiness = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const atRisk = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY,
    false
  );

  return (
    !exporterUkimsNumber &&
    !importerUkimsNumber &&
    isNiShipment(values) &&
    isBusiness &&
    !atRisk &&
    isFlagRequired(saebiFlags, FORM.NETWORK_FIELDS.UKIMS_NUMBER)
  );
};

export const isDeliveryDescriptionRequired = saebiFlags =>
  isInvoiceAvailable(saebiFlags) &&
  isFlagRequired(saebiFlags, FORM.NETWORK_FIELDS.CONTENTS_REQUIRED);

export const getInvoiceRequiredFields = (values, references = {}) => {
  const saebiFlags = getSaebiFlags(
    references,
    get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
  );
  const isInvoiceAllowed = isInvoiceAvailable(saebiFlags);

  if (!isInvoiceAllowed) {
    return {};
  }

  const isBusiness = get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const isUkimsMandatory = isUkimsRequired(values, saebiFlags);

  return {
    [FORM.SHIPMENT_FIELDS.CURRENCY.KEY]: isInvoiceAllowed,
    [FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY]: isInvoiceAllowed,
    [FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION.KEY]:
      isDeliveryDescriptionRequired(saebiFlags),
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY]: isFlagRequired(
      saebiFlags,
      FORM.NETWORK_FIELDS.EXPORT_REASON_REQUIRED
    ),
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: true,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]: isFlagRequired(
      saebiFlags,
      FORM.NETWORK_FIELDS.FDA_REQUIRED
    ),
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isUkimsMandatory,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: true,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      !isBusiness && isFlagRequired(values, FORM.NETWORK_FIELDS.PID_NUMBER),
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]:
      isBusiness && isFlagRequired(values, FORM.NETWORK_FIELDS.VAT_NUMBER),
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]: isEoriRequired(
      values,
      saebiFlags
    ),
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]: isUkimsMandatory,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IOSS_NUMBER.KEY]: isFlagRequired(
      saebiFlags,
      FORM.NETWORK_FIELDS.TAX_REQUIRED
    ),
  };
};

export const getParcelDetailsRequiredFields = (
  values,
  references,
  countries
) => {
  const baseRequiredFields = [
    FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_CITY.KEY,

    FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_CITY.KEY,

    FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY,
    FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
    FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
  ];

  if (get(values, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR) {
    baseRequiredFields.push(FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
  }

  PostcodeHelpers.isPostcodeRequired(
    get(values, FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
    countries
  ) && baseRequiredFields.push(FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY);

  PostcodeHelpers.isPostcodeRequired(
    get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY),
    countries
  ) && baseRequiredFields.push(FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY);

  return baseRequiredFields;
};

export const getAddressDetailsRequiredFields = (values, references) => ({
  ...getInvoiceRequiredFields(values, references),
  [FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY]: true,
  [FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY]: true,
  [FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY]: true,
  [FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY]: true,
  [FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY]: true,
  [FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY]: true,
});

export const getFullShipmentRequiredFields = (
  values,
  references,
  countries
) => {
  const parcelDetailsRequiredFields = getParcelDetailsRequiredFields(
    values,
    references,
    countries
  );
  const addressDetailsRequiredFields = ObjectUtil.convertObjectKeysToArray(
    getAddressDetailsRequiredFields(values, references)
  );

  return [...parcelDetailsRequiredFields, ...addressDetailsRequiredFields];
};

export const getTotalParcelsValue = parcels =>
  parcels.reduce(
    (totalParcelsValue, parcel) =>
      totalParcelsValue +
      Number(parcel._value ?? 0) * Number(parcel._quantity ?? 0),
    0
  );
