import { filter, get } from 'lodash';
import moment from 'moment-timezone';
import { REFERENCE_NAME } from '../constants';
import { DATE_FORMAT, FORM, STRINGS } from '../../../constants';
import {
  AddressBookUtil,
  AddressUtil,
  DateUtil,
  Formatters,
  NetworkUtil,
  ObjectUtil,
} from '../../../utils';
import { SHIPMENT_FIELDS } from '../../../constants/forms';
import { requireKeysSchema, validateWithJoi } from '../../../utils/validators';
import { shipmentSchema } from '../validators/shipment';
import { ShipmentHelpers } from '../../../helpers';

export const filterCollectionDatesInPast = dates =>
  filter(dates, date =>
    moment.tz(
      `${date.date} ${date.collectionCutOff}`,
      DATE_FORMAT.DATE_TIME_FORMAT,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
  );

export const formatShipmentDate = ({ date, collectionCutOff }) =>
  moment
    .tz(
      `${date} ${collectionCutOff}`,
      DATE_FORMAT.DATE_TIME_FORMAT,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
    .format(DATE_FORMAT.DATE_TIME_FORMAT);

export const getDefaultShipmentDate = defaultCutOff => {
  const nextWorkingDay = DateUtil.getNextWorkingDay();
  return formatShipmentDate({
    date: nextWorkingDay.format(DATE_FORMAT.YYYY_MM_DD_FORMAT),
    collectionCutOff: defaultCutOff,
  });
};

export const getCurrencySymbol = (value = STRINGS.CURRENCY_SYMBOLS.GBP) =>
  STRINGS.CURRENCY_SYMBOLS[value];

export const getValidationContext = (values, references, countries) => {
  const deliveryCountryValue = AddressUtil.getCountryValue({
    countries,
    countryCode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY),
  });
  const collectionCountryValue = AddressUtil.getCountryValue({
    countries,
    countryCode: get(values, FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
  });

  return {
    ...NetworkUtil.getMaxWeightAndParcelNumber(
      get(values, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY),
      references
    ),
    deliveryCountryValue,
    collectionCountryValue,
  };
};

export const validateFullShipmentSync = (values, references, countries) => {
  const context = getValidationContext(values, references, countries);

  const requiredFields = ShipmentHelpers.getFullShipmentRequiredFields(
    values,
    references,
    countries
  );
  const schema = requireKeysSchema(
    shipmentSchema({
      ...context,
      withContactDetails: true,
    }),
    requiredFields
  );
  const errors = validateWithJoi(values, schema, {
    allowUnknown: true,
  });

  return ObjectUtil.getLeafValues(errors);
};

export const getAddressBookFieldsPath = type => {
  const data = {
    delivery: {
      referencePath: REFERENCE_NAME.DELIVERY_ADDRESS_BOOK,
      fieldPath: FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
    },
    collection: {
      referencePath: REFERENCE_NAME.COLLECTION_ADDRESS_BOOK,
      fieldPath: FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
    },
  };

  return data[type];
};

export const getDisplayedPrice = price =>
  price ? Formatters.formatPrice(price) : '-.-';

export const getDisplayedExtraPrice = price =>
  price ? `+${Formatters.formatPrice(price)}` : STRINGS.FREE;

export const mapAddressDetailsToAddressBook = details =>
  AddressBookUtil.normalizeAddressBookDataForPayload({
    data: {
      address: details.address,
      email: details.contactDetails.email || details.notificationDetails?.email,
      nickname: details.contactDetails.contactName,
      contactName: details.contactDetails.contactName,
      phoneNumber:
        details.notificationDetails?.mobile || details.contactDetails.telephone,
      eoriNumber: details.eoriNumber,
      vatNumber: details.vatNumber,
      pidNumber: details.pidNumber,
      ukimsNumber: details.ukimsNumber,
      // todo remove if in the future we will not use it
      iossNumber: details.iossNumber,
    },
  });
