import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';

const ProductTableItem = ({ item, column }) => {
  const { path, render, bodyColspan, align } = column;

  if (!path && !render) {
    return null;
  }

  return (
    <TableCell sx={{ p: 2 }} colSpan={bodyColspan} align={align}>
      {path ? item[path] || '--' : render(item, item.isDefaultValue)}
    </TableCell>
  );
};

const ProductTable = ({
  ariaLabel,
  config,
  items,
  selectedItemId,
  onRowSelect,
}) => (
  <Table aria-label={ariaLabel} size='small'>
    <TableHead>
      <TableRow>
        {config.columns.map(
          ({ heading, width, headColspan, align }, index) =>
            heading && (
              <TableCell
                key={index}
                width={width}
                colSpan={headColspan}
                align={align}
              >
                {heading}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map(item => (
        <TableRow
          key={item.id}
          selected={selectedItemId && selectedItemId === item.id}
          onClick={() => onRowSelect && onRowSelect(item)}
          sx={{ cursor: onRowSelect && !item.isDefaultValue && 'pointer' }}
        >
          {config.columns.map((column, index) => (
            <ProductTableItem
              key={`${column.path}-${index}`}
              item={item}
              column={column}
            />
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

ProductTable.propTypes = {
  ariaLabel: PropTypes.string,
  config: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        heading: PropTypes.string,
        width: PropTypes.string,
        headColspan: PropTypes.number,
        bodyColspan: PropTypes.number,
        align: PropTypes.oneOf(['left', 'right', 'center']),
        render: PropTypes.func,
      })
    ).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isDefaultValue: PropTypes.bool,
      [PropTypes.string]: PropTypes.any,
    })
  ).isRequired,
  selectedItemId: PropTypes.string,
  onRowSelect: PropTypes.func,
};

export default ProductTable;
