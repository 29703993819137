import Close from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import { COMMODITY_FINDER } from '../../constants/strings';
import { DEFAULT_PAGINATION_SEARCH_MODAL_SIZE } from '../../constants/pagination';
import useErrorHandler from '../../hooks/useErrorHandler';
import { FIELDS } from '../../constants/forms';
import { getCommodities } from '../../apis/commodities';
import CommodityList from './components/CommodityList';

const CommodityFinderModal = ({ open, onClose, setCommoditySelected }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [commodities, setCommodities] = useState({});
  const [levels, setLevels] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { errorHandler } = useErrorHandler();
  const formRef = useRef(null);
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const searchPageSize = DEFAULT_PAGINATION_SEARCH_MODAL_SIZE;

  const performSearch = async searchString => {
    try {
      setIsLoadingMore(true);
      const args = {
        searchPage: currentPage,
        searchPageSize,
        searchString: searchString.trim(),
      };

      const data = await getCommodities(args);

      setCommodities(prev => ({
        ...prev,
        ...data?.result?.commodities,
      }));
      setLevels(prev => [...(prev || []), ...(data?.result?.levels || [])]);
      setTotalResults(data?.totalResults);
      setSearchSubmitted(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleFormSubmit = values => {
    const { commodity } = values;
    if (commodity) {
      performSearch(commodity);
    }
  };

  const clearInput = () => {
    setSearchValue('');
    setCommodities({});
    setLevels([]);
    setSearchSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      setCurrentPage(1);
    }
  }, [open]);

  useEffect(() => {
    if (currentPage > 1) {
      performSearch(searchValue);
    }
  }, [currentPage]);

  const hasMore = Object.keys(commodities).length < totalResults;

  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setCurrentPage(1);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.contrastText,
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '75%',
          },
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.primary.contrastText,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography variant='h3'>{COMMODITY_FINDER.TITLE}</Typography>
            <IconButton
              aria-label='close'
              sx={{ mr: 0.5 }}
              onClick={() => {
                onClose();
                setCurrentPage(1);
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />

          <Box sx={{ padding: 2 }}>
            <Form
              onSubmit={handleFormSubmit}
              ref={formRef}
              initialValues={{ commodity: '' }}
              render={({ handleSubmit }) => (
                <Box
                  component='form'
                  autoComplete='off'
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1.5,
                  }}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Field name={FIELDS.COMMODITY.KEY}>
                      {({ input, meta }) => (
                        <>
                          <TextField
                            id='commodity-code'
                            label={FIELDS.COMMODITY.LABEL}
                            placeholder={FIELDS.COMMODITY.PLACEHOLDER}
                            fullWidth
                            size='medium'
                            error={!!(meta.touched && meta.error)}
                            {...input}
                            value={searchValue}
                            helperText={
                              meta.touched && meta.error
                                ? meta.error
                                : COMMODITY_FINDER.SUBTITLE
                            }
                            onChange={e => {
                              input.onChange(e);
                              setSearchValue(e.target.value);
                              if (e.target.value.trim() === '') {
                                setCommodities({});
                                setLevels([]);
                              }
                              if (searchSubmitted) {
                                setSearchSubmitted(false);
                              }
                            }}
                            sx={{
                              borderRadius: '4px',
                              height: '40px',
                              mr: 1,
                              '& .MuiInputBase-root': {
                                height: '40px',
                              },
                              '& .MuiOutlinedInput-root': {
                                padding: '0',
                              },
                              '& .MuiInputLabel-root': {
                                top: '-6px',
                              },
                              '& .MuiFormHelperText-root': {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                marginLeft: '8px',
                                maxWidth: {
                                  xs: '100%',
                                  sm: '75%',
                                },
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <IconButton onClick={clearInput}>
                                  <ClearIcon />
                                </IconButton>
                              ),
                            }}
                          />
                          <Button
                            variant='contained'
                            sx={{
                              height: '40px',
                            }}
                            type='submit'
                          >
                            {COMMODITY_FINDER.SEARCH_BUTTON}
                          </Button>
                        </>
                      )}
                    </Field>
                  </FormControl>
                </Box>
              )}
            />
          </Box>
          <Divider />
        </Box>
        <Box sx={{ padding: 2 }}>
          <CommodityList
            pristine={!searchSubmitted}
            commodities={commodities}
            levels={levels}
            setCommoditySelected={setCommoditySelected}
            onClose={onClose}
          />
          {commodities && searchSubmitted && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              {hasMore && (
                <Button
                  variant='outlined'
                  onClick={handleLoadMore}
                  disabled={isLoadingMore}
                  sx={{ marginTop: 2 }}
                >
                  {COMMODITY_FINDER.LOAD_MORE}
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Grid
          container
          justifyContent='center'
          sx={{ padding: '8px 16px 16px', position: 'relative' }}
        >
          <Divider sx={{ position: 'absolute', width: '100%', top: '0' }} />
        </Grid>
      </Box>
    </Modal>
  );
};

CommodityFinderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setCommoditySelected: PropTypes.func,
};

export default CommodityFinderModal;
