import { Grid, Paper, Typography } from '@mui/material';
import { Field } from 'react-final-form';

import { FORM, STRINGS } from '../../../constants';
import ActionButton from '../../../components/ActionButton';
import { ISSUE_TYPES } from '../constants';

const ClaimTypeSelect = () => (
  <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
    <Typography variant='h3' sx={{ mb: 1 }}>
      {STRINGS.CHOOSE_ONE_OF_OPTIONS}
    </Typography>
    <Field name={FORM.HELP_FIELDS.ISSUE_TYPE.KEY}>
      {({ input }) => (
        <Grid container spacing={2} sx={{ mt: 1, alignItems: 'stretch' }}>
          <Grid container item md={4} xs={12}>
            <ActionButton
              selected={input.value === ISSUE_TYPES.DAMAGED.KEY}
              title={STRINGS.DAMAGED}
              bodyText={STRINGS.MY_PARCEL_DAMAGED}
              cardOnclick={() => {
                input.onChange(ISSUE_TYPES.DAMAGED.KEY);
              }}
              additionalInfo={false}
            />
          </Grid>
          <Grid container item md={4} xs={12}>
            <ActionButton
              selected={input.value === ISSUE_TYPES.NOT_ARRIVED.KEY}
              title={STRINGS.NOT_ARRIVED}
              bodyText={STRINGS.MY_PARCEL_NOT_ARRIVED}
              cardOnclick={() => {
                input.onChange(ISSUE_TYPES.NOT_ARRIVED.KEY);
              }}
              additionalInfo={false}
            />
          </Grid>
          <Grid container item md={4} xs={12}>
            <ActionButton
              selected={input.value === ISSUE_TYPES.MISSING_CONTENTS.KEY}
              title={STRINGS.MISSING_CONTENTS}
              bodyText={STRINGS.MY_PARCEL_HAS_CONTENTS_MISSING}
              cardOnclick={() => {
                input.onChange(ISSUE_TYPES.MISSING_CONTENTS.KEY);
              }}
              additionalInfo={false}
            />
          </Grid>
        </Grid>
      )}
    </Field>
  </Paper>
);

export default ClaimTypeSelect;
