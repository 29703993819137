import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep, get, set, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Autocomplete, TextField as TextFieldRFF } from 'mui-rff';
import { v4 as uuidv4 } from 'uuid';
import { useOverlay } from '../../../../features/Overlay/index';
import { BasketActions } from '../../../../redux/basket';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import ProhibitedModal from '../../../../components/ProhibitedModal';
import { CommodityFinderModal } from '../../../../features/CommodityFinderModal';
import CommodityCard from '../../../../features/CommodityFinderModal/components/CommodityCard';
import { productSchema } from '../../validators/parcel';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import Summary from '../../components/Summary';
import { FORM, STRINGS } from '../../../../constants';
import { useAnalytics, Validators, ObjectUtil } from '../../../../utils';
import Debugger from '../../../../components/Debugger';
import { PRODUCT_TABLE_CONFIG, TOTAL_TABLE_CONFIG } from './constants';
import { calculateTotal } from './helpers';
import ProductTable from './components/ProductTable';
import * as CustomsDetailsSelectors from './selectors';

const CustomsDetails = ({ nextStep, values, setValues }) => {
  const theme = useTheme();
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const [searchParams] = useSearchParams();
  const { Trackable } = useAnalytics();
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelectors.getCountries);
  const availableFields = useSelector(
    CustomsDetailsSelectors.getAvailableFields
  );
  const requiredFields = useSelector(CustomsDetailsSelectors.getRequiredFields);
  const [checkedProhibitedItem, setCheckedProhibitedItem] = useState(false);
  const [isOpenProhibitedModal, setIsOpenProhibitedModal] = useState(false);
  const [commodityFinderOpen, setCommodityFinderOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // TODO: No info on where to set products in case of several parcels, temporary solution
  const products = useMemo(
    () => get(values, `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`, []),
    [values]
  );
  const total = useMemo(() => calculateTotal(products), [products]);
  const currency = get(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY);
  const validateProductSchema = useMemo(
    () =>
      Validators.requireKeysSchema(
        productSchema,
        ObjectUtil.convertObjectKeysToArray(requiredFields)
      ),
    [requiredFields]
  );
  const validateProduct = useCallback(
    values =>
      Validators.validateWithJoi(values, validateProductSchema, {
        allowUnknown: true,
      }),
    [validateProductSchema]
  );

  const onSubmit = useCallback(async () => {
    try {
      overlay.show();
      const newValues = cloneDeep(values);
      const basketItemId = searchParams.get('basketItemId');

      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: newValues,
      };

      await dispatch(
        BasketActions.updateBasketItem(updatedBasketItem)
      ).unwrap();

      nextStep(newValues);
    } catch (e) {
      snackbar.showError({
        message: STRINGS.FAILED_TO_SAVE_BASKET,
      });
    } finally {
      overlay.hide();
    }
  }, [dispatch, nextStep, overlay, searchParams, snackbar, values]);

  const handleOpenCommodityModal = () => {
    setCommodityFinderOpen(true);
  };
  const handleCloseCommodityModal = () => {
    setCommodityFinderOpen(false);
  };

  const handleSetCommoditySelected = (form, commodityData) => {
    form.batch(() => {
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_DESCRIPTION.KEY,
        commodityData?.commodity?.description || ''
      );
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY,
        commodityData?.commodity?.code || ''
      );
    });
  };
  const handleSaveProduct = useCallback(
    formValues => {
      const newValues = cloneDeep(values);
      const newProducts = cloneDeep(products);
      const productId = formValues.id || uuidv4();
      const existingProductIndex = products.findIndex(
        product => product.id === productId
      );
      const updatedProduct = { ...formValues, id: productId };

      if (existingProductIndex > -1) {
        newProducts[existingProductIndex] = updatedProduct;
      } else {
        newProducts.push(updatedProduct);
      }

      set(
        newValues,
        `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`,
        newProducts
      );

      setValues(newValues);
      setSelectedProduct(updatedProduct);
    },
    [values, products, setValues]
  );
  const handleDeleteProduct = useCallback(
    productToDelete => {
      const newValues = cloneDeep(values);
      const newProducts = products.filter(
        product => product.id !== productToDelete.id
      );

      set(
        newValues,
        `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`,
        newProducts
      );

      setValues(newValues);

      if (isEmpty(newProducts)) {
        setSelectedProduct(null);
      }
    },
    [products, setValues, values]
  );

  return (
    <Trackable loadId={{}}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        {/* NOTE: In this case we need to have a separate form which will set values to Wizard on submit */}
        <Form
          onSubmit={onSubmit}
          validate={validateProduct}
          initialValues={selectedProduct || {}}
        >
          {({ values: formValues, invalid, errors, form }) => (
            <>
              <Grid item xs={12} md={8}>
                <form id='customsDetailsForm'>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                      {STRINGS.CUSTOMS_PAGE.TITLE}
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 2 }}>
                      {STRINGS.CUSTOMS_PAGE.PARCEL_DETAILS}
                    </Typography>
                    <Grid
                      item
                      sx={{
                        pr: 1,
                        mt: { xs: 2, md: 0 },
                      }}
                    >
                      <TextFieldRFF
                        name={FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY}
                        label={FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.LABEL}
                        helperText={STRINGS.CUSTOMS_PAGE.PROD_DESC_EXAMPLE}
                        required
                        size='small'
                      />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        mt: 1,
                      }}
                    >
                      <Checkbox
                        checked={checkedProhibitedItem}
                        onChange={event =>
                          setCheckedProhibitedItem(event.target.checked)
                        }
                        sx={{ color: 'primary.main' }}
                      />
                      <Typography variant='caption'>
                        {STRINGS.SALES_CONDITIONS.CONFIRM_THAT_MY_SHIPMENT}
                        <Typography
                          variant='caption'
                          onClick={() => setIsOpenProhibitedModal(true)}
                          sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                          }}
                        >
                          {STRINGS.PROHIBITED_MODAL.PROHIBITED_ITEMS}
                        </Typography>
                      </Typography>
                    </Box>
                    {!availableFields.isDescriptionOnly && (
                      <>
                        <Grid container sx={{ mt: 0 }} spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY}
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.TOTAL_QUANTITY_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY}
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.PRODUCT_VALUE_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY
                              }
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.PRODUCT_WEIGHT_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={12} md={12}>
                            <Field
                              // TODO: display as required
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY
                              }
                            >
                              {({ input }) => (
                                <>
                                  {input.value ? (
                                    <CommodityCard
                                      commodity={{
                                        code: input.value,
                                        description:
                                          formValues[
                                            FORM.SHIPMENT_FIELDS
                                              .PRODUCT_COMMODITY_DESCRIPTION.KEY
                                          ],
                                        applyCode: true,
                                      }}
                                      setCommoditySelected={() =>
                                        handleSetCommoditySelected(form, null)
                                      }
                                      onEditClick={handleOpenCommodityModal}
                                    />
                                  ) : (
                                    <Button
                                      variant='outlined'
                                      onClick={handleOpenCommodityModal}
                                    >
                                      {STRINGS.ADD_COMMODITY_CODE}
                                    </Button>
                                  )}
                                  <CommodityFinderModal
                                    open={commodityFinderOpen}
                                    setCommoditySelected={commodity => {
                                      handleSetCommoditySelected(
                                        form,
                                        commodity
                                      );
                                    }}
                                    onClose={handleCloseCommodityModal}
                                  />
                                </>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {availableFields[
                      FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY
                    ] && (
                      <Box sx={{ mt: 3 }}>
                        <Autocomplete
                          options={countries}
                          getOptionLabel={option => option.countryName}
                          getOptionValue={option => option.countryKey}
                          name={
                            FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY
                          }
                          renderInput={params => (
                            <TextField
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                  .LABEL
                              }
                              variant='outlined'
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                  .KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                    .KEY
                                ]
                              }
                              size='small'
                              helperText={
                                STRINGS.CUSTOMS_PAGE
                                  .ENTER_OR_SELECT_COUNTRY_OF_ORIGIN
                              }
                              {...params}
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} md={12}>
                        <ProductTable
                          ariaLabel='products table'
                          config={PRODUCT_TABLE_CONFIG(
                            currency,
                            availableFields.isDescriptionOnly,
                            handleDeleteProduct
                          )}
                          items={
                            isEmpty(products)
                              ? [{ isDefaultValue: true }]
                              : products
                          }
                          selectedItemId={selectedProduct?.id}
                          onRowSelect={setSelectedProduct}
                        />
                      </Grid>
                    </Grid>
                    {!availableFields.isDescriptionOnly && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} md={12}>
                          <ProductTable
                            ariaLabel='total products table'
                            config={TOTAL_TABLE_CONFIG(currency)}
                            items={[total]}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} md={6}>
                        <Button
                          startIcon={<Add />}
                          onClick={() => setSelectedProduct(null)}
                        >
                          {STRINGS.CUSTOMS_PAGE.ADD_ANOTHER_PRODUCT}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display='flex'
                        justifyContent='flex-end'
                      >
                        <Button
                          variant='contained'
                          disabled={invalid}
                          onClick={() => handleSaveProduct(formValues)}
                        >
                          {STRINGS.CUSTOMS_PAGE.SAVE_PRODUCT}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Debugger>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  </Debugger>
                </form>
              </Grid>
              <Grid item xs={12} md={4}>
                <Summary submitDisabled={isEmpty(products)} />
              </Grid>
            </>
          )}
        </Form>
      </Grid>
      <ProhibitedModal
        isOpen={isOpenProhibitedModal}
        onClose={() => setIsOpenProhibitedModal(false)}
      />
    </Trackable>
  );
};

export default CustomsDetails;
