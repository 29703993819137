import { get } from 'lodash';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { FREE_PROTECTION_DEDUCTION } from '../../constants';

export const getFieldsToUpdate = ({ network, values }) => {
  const isLiabilityValueAvailable =
    network.insurance && !get(values, SHIPMENT_FIELDS.LIABILITY_VALUE.KEY);

  return {
    [SHIPMENT_FIELDS.NETWORK_DESC.KEY]: network.networkDesc,
    [SHIPMENT_FIELDS.NETWORK_BUS_ZONE.KEY]: network.busZone,
    [SHIPMENT_FIELDS.LIABILITY.KEY]: network.insurance,
    ...(isLiabilityValueAvailable && {
      [SHIPMENT_FIELDS.LIABILITY_VALUE.KEY]: FREE_PROTECTION_DEDUCTION,
    }),
  };
};
