import { Field } from 'react-final-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

export const FormInput = ({
  name,
  label,
  disabled,
  required,
  key,
  inputProps,
  fieldProps,
  ...props
}) => (
  <Field name={name} {...fieldProps} key={key}>
    {({ input, meta }) => (
      <TextField
        {...input}
        label={label}
        disabled={disabled}
        required={required}
        inputProps={inputProps}
        error={meta.error && meta.touched}
        helperText={meta.touched && meta.error}
        {...props}
      />
    )}
  </Field>
);

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  key: PropTypes.string,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
};
