import { get } from 'lodash';
import { ShipmentHelpers } from '../../../../helpers';
import { FORM, SHIPMENT } from '../../../../constants';

export const calculateTotal = products =>
  products.reduce(
    (acc, product) => {
      const { productQty, unitWeight, unitValue } = product;
      const quantity = Number(productQty);
      const weight = Number(unitWeight);
      const value = Number(unitValue);
      const totalWeight = weight * quantity;
      const totalValue = value * quantity;
      return {
        quantity: acc.quantity + quantity,
        weight: acc.weight + totalWeight,
        value: acc.value + totalValue,
      };
    },
    {
      quantity: 0,
      weight: 0,
      value: 0,
    }
  );

export const getProductAvailableFields = (values, references) => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(
    references,
    get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
  );
  const isDescriptionOnly = ShipmentHelpers.isFlagDescription(
    saebiFlags,
    FORM.NETWORK_FIELDS.PROD_REQUIRED
  );

  return {
    isDescriptionOnly,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY]:
      !isDescriptionOnly &&
      !ShipmentHelpers.isFlagNeedles(
        saebiFlags,
        FORM.NETWORK_FIELDS.COUNTRY_OF_ORIGIN
      ),
  };
};

export const isCommodityRequired = saebiFlags =>
  [SHIPMENT.REQUIRED_TYPE.MANDATORY, SHIPMENT.REQUIRED_TYPE.OPTIONAL].includes(
    get(saebiFlags, FORM.NETWORK_FIELDS.PROD_REQUIRED)
  ) &&
  ShipmentHelpers.isFlagRequired(
    saebiFlags,
    FORM.NETWORK_FIELDS.COMMODITY_REQUIRED
  );

export const getProductRequiredFields = (values, references) => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(
    references,
    get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY)
  );

  if (
    !ShipmentHelpers.isFlagRequired(
      saebiFlags,
      FORM.NETWORK_FIELDS.PROD_REQUIRED
    )
  ) {
    return {};
  }

  if (
    ShipmentHelpers.isFlagDescription(
      saebiFlags,
      FORM.NETWORK_FIELDS.PROD_REQUIRED
    )
  ) {
    return { [FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY]: true };
  }

  return {
    [FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY]:
      isCommodityRequired(saebiFlags),
    [FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY]:
      ShipmentHelpers.isFlagRequired(
        saebiFlags,
        FORM.NETWORK_FIELDS.COUNTRY_OF_ORIGIN
      ),
  };
};
